import PropTypes from 'prop-types';

const BlogSchema = ({ context = true, content }) => {
  return {
    ...(context ? { '@context': 'https://schema.org' } : {}),
    '@type': 'Blog',
    blogPost: content.map((item) => ({
      '@type': 'BlogPosting',
      headline: item.fields.title,
      image: item.fields.image.fields.file.url,
      author: {
        '@type': 'Person',
        name: 'Registro de Marcas Chile',
      },
      datePublished: item.fields.date,
      dateModified: item.fields.date,
      url: `https://registro-de-marcas.cl/blog/${item.fields.slug}`,
    })),
  };
};

const BlogSchemaScriptTag = ({ context = true, content }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(BlogSchema({ context, content })),
      }}
    />
  );
};

BlogSchema.propTypes = {
  context: PropTypes.bool,
  content: PropTypes.object.isRequired,
};

BlogSchemaScriptTag.propTypes = {
  context: PropTypes.bool,
  content: PropTypes.object.isRequired,
};

export { BlogSchema, BlogSchemaScriptTag };
