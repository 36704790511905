import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Menu, X } from 'lucide-react';
import PropTypes from 'prop-types';

export default function Header({
  generalInfo,
  setIsContactModalOpen,
  isCheckoutPage = false,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <a title="Inicio" href="/" className="flex items-center space-x-2">
            {generalInfo && generalInfo.logo && (
              <img
                src={generalInfo.logo.fields.file.url}
                alt={generalInfo.logo.fields.title}
                className="w-10 h-10"
              />
            )}
            <span className="text-2xl font-bold text-blue-800">
              {generalInfo ? generalInfo.name : 'Registro de Marcas Chile'}
            </span>
          </a>
          <nav className="hidden md:flex items-center space-x-6">
            {generalInfo &&
              generalInfo.headerLinks &&
              generalInfo.headerLinks.map((link, index) => {
                if (link.fields.type === 'Page Section') {
                  return (
                    <HashLink
                      smooth
                      key={index}
                      className="text-blue-600 hover:text-blue-800 transition-colors font-bold"
                      title={link.fields.title}
                      to={link.fields.url}
                    >
                      {link.fields.text}
                    </HashLink>
                  );
                } else {
                  return (
                    <Link
                      key={index}
                      className="text-blue-600 hover:text-blue-800 transition-colors font-bold"
                      title={link.fields.title}
                      {...(link.fields.type === 'URL Link' && {
                        to: link.fields.url,
                      })}
                      {...(link.fields.type === 'Contact Form' && {
                        onClick: () => setIsContactModalOpen(true),
                      })}
                    >
                      {link.fields.text}
                    </Link>
                  );
                }
              })}
            {!isCheckoutPage ? (
              <Link
                title="Registra tu Marca"
                to="/checkout"
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-green-500 text-white hover:bg-green-600 transition-colors text-lg px-8 py-3 uppercase font-bold"
              >
                Registra tu Marca
              </Link>
            ) : (
              <></>
            )}
          </nav>
          <button
            aria-label="Menu"
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-blue-800" />
            ) : (
              <Menu className="h-6 w-6 text-blue-800" />
            )}
          </button>
        </div>
        {isMenuOpen && (
          <nav className="mt-4 md:hidden">
            {generalInfo &&
              generalInfo.headerLinks &&
              generalInfo.headerLinks.map((link, index) => {
                if (link.fields.type === 'Page Section') {
                  return (
                    <HashLink
                      smooth
                      key={index}
                      className="block py-2 text-blue-600 hover:text-blue-800 transition-colors font-medium"
                      title={link.fields.title}
                      to={link.fields.url}
                    >
                      {link.fields.text}
                    </HashLink>
                  );
                } else {
                  return (
                    <Link
                      key={index}
                      className="block py-2 text-blue-600 hover:text-blue-800 transition-colors font-medium"
                      title={link.fields.title}
                      {...(link.fields.type === 'URL Link' && {
                        to: link.fields.url,
                      })}
                      {...(link.fields.type === 'Contact Form' && {
                        onClick: () => setIsContactModalOpen(true),
                      })}
                    >
                      {link.fields.text}
                    </Link>
                  );
                }
              })}
            <Link
              title="Registra tu Marca"
              to="/checkout"
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-green-500 text-white hover:bg-green-600 transition-colors text-lg px-8 py-3 uppercase font-bold"
            >
              Registra tu Marca
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {
  generalInfo: PropTypes.object,
  setIsContactModalOpen: PropTypes.func,
  isCheckoutPage: PropTypes.bool,
};
