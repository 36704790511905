import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTiktok,
} from 'react-icons/fa';
import PropTypes from 'prop-types';

export default function Footer({ generalInfo, setIsContactModalOpen }) {
  return (
    <footer className="bg-blue-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">
              {generalInfo ? generalInfo.name : 'Registro de Marcas Chile'}
            </h3>
            <p className="text-sm text-blue-200">
              {generalInfo ? generalInfo.footerSlogan : ''}
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Enlaces Rápidos</h4>
            <ul className="space-y-2">
              {generalInfo &&
                generalInfo.footerLinks &&
                generalInfo.footerLinks.map((link, index) => (
                  <li key={index}>
                    {link.fields.type === 'Page Section' ? (
                      <HashLink
                        smooth
                        className="text-blue-200 hover:text-white transition-colors"
                        title={link.fields.title}
                        to={link.fields.url}
                      >
                        {link.fields.text}
                      </HashLink>
                    ) : (
                      <Link
                        className="text-blue-200 hover:text-white transition-colors"
                        title={link.fields.title}
                        {...(link.fields.type === 'URL Link' && {
                          to: link.fields.url,
                        })}
                        {...(link.fields.type === 'Contact Form' && {
                          onClick: () => setIsContactModalOpen(true),
                        })}
                      >
                        {link.fields.text}
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Contacto</h4>
            <p className="text-blue-200 mb-2">
              {generalInfo && (
                <a title="Email" href={`mailto:${generalInfo.contactEmail}`}>
                  {generalInfo.contactEmail}
                </a>
              )}
            </p>
            <p className="text-blue-200">
              {generalInfo && generalInfo.contactPhone && (
                <a
                  title="Teléfono"
                  href={`tel:${generalInfo.contactPhone.replace(/\s/g, '')}`}
                >
                  {generalInfo.contactPhone}
                </a>
              )}
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Síguenos</h4>
            <div className="flex space-x-4">
              {generalInfo &&
                generalInfo.socialLinks &&
                generalInfo.socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    className="text-blue-200 hover:text-white transition-colors"
                  >
                    {link.includes('facebook.com') && (
                      <FaFacebook className="w-6 h-6" aria-label="Facebook" />
                    )}
                    {link.includes('instagram.com') && (
                      <FaInstagram className="w-6 h-6" aria-label="Instagram" />
                    )}
                    {(link.includes('twitter.com') ||
                      link.includes('x.com')) && (
                      <FaTwitter className="w-6 h-6" aria-label="Twitter" />
                    )}
                    {link.includes('linkedin.com') && (
                      <FaLinkedin className="w-6 h-6" aria-label="LinkedIn" />
                    )}
                    {link.includes('tiktok.com') && (
                      <FaTiktok className="w-6 h-6" aria-label="TikTok" />
                    )}
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-blue-800 text-center">
          <p className="text-blue-200">
            {generalInfo ? generalInfo.copyright : ''}
          </p>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  generalInfo: PropTypes.object,
  setIsContactModalOpen: PropTypes.func,
};
