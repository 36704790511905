import PropTypes from 'prop-types';
import { StaticRouter } from 'react-router-dom/server';
import '../styles/global.css';
import { usePageContext } from 'vike-react/usePageContext';

import Footer from '../components/Footer';
import Header from '../components/Header';

export default function LayoutDefault({ children }) {
  const { Page, pageProps, urlPathname } = usePageContext();
  const { generalInfo } = pageProps;

  const isCheckoutPage = urlPathname.includes('/checkout');
  const isHomePage = urlPathname === '/';

  return (
    <StaticRouter location={urlPathname}>
      <div
        className={`min-h-screen font-sans ${isHomePage ? 'bg-gradient-to-br from-blue-50 to-green-50' : isCheckoutPage ? 'flex flex-col bg-gradient-to-br from-blue-50 to-green-50' : 'flex flex-col bg-gray-50'}`}
      >
        <Header generalInfo={generalInfo} isCheckoutPage={isCheckoutPage} />
        <Page {...pageProps}>{children}</Page>
        <Footer generalInfo={generalInfo} />
      </div>
    </StaticRouter>
  );
}

LayoutDefault.propTypes = {
  children: PropTypes.node.isRequired,
};
