import PropTypes from 'prop-types';

const FAQSchema = ({ context = true, content }) => {
  return {
    ...(context ? { '@context': 'https://schema.org' } : {}),
    '@type': 'FAQPage',
    mainEntity: content.map((item) => ({
      '@type': 'Question',
      name: item.fields.question,
      acceptedAnswer: { '@type': 'Answer', text: item.fields.answer },
    })),
  };
};

const FAQSchemaScriptTag = ({ context = true, content }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(FAQSchema({ context, content })),
      }}
    />
  );
};

FAQSchema.propTypes = {
  context: PropTypes.bool,
  content: PropTypes.array.isRequired,
};

FAQSchemaScriptTag.propTypes = {
  context: PropTypes.bool,
  content: PropTypes.array.isRequired,
};

export { FAQSchema, FAQSchemaScriptTag };
