import PropTypes from 'prop-types';

const OrganizationSchema = ({
  context = true,
  name,
  url,
  urlLogo,
  relatedLinks,
  phone,
}) => {
  return {
    ...(context ? { '@context': 'https://schema.org' } : {}),
    '@type': 'Organization',
    name,
    url,
    logo: urlLogo,
    sameAs: relatedLinks,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phone,
      contactType: 'customer service',
      areaServed: 'CL',
      availableLanguage: 'es',
    },
  };
};

const OrganizationSchemaScriptTag = ({
  context = true,
  name,
  url,
  urlLogo,
  relatedLinks,
  phone,
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          OrganizationSchema({
            context,
            name,
            url,
            urlLogo,
            relatedLinks,
            phone,
          })
        ),
      }}
    />
  );
};

OrganizationSchema.propTypes = {
  context: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlLogo: PropTypes.string.isRequired,
  relatedLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  phone: PropTypes.string.isRequired,
};

OrganizationSchemaScriptTag.propTypes = {
  context: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlLogo: PropTypes.string.isRequired,
  relatedLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  phone: PropTypes.string.isRequired,
};

export { OrganizationSchema, OrganizationSchemaScriptTag };
