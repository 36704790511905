import PropTypes from 'prop-types';

const ServiceSchema = ({
  context = true,
  name,
  url,
  description,
  organizationName,
  organizationUrl,
}) => {
  return {
    ...(context ? { '@context': 'https://schema.org' } : {}),
    '@type': 'Service',
    name,
    description,
    provider: {
      '@type': 'Organization',
      name: organizationName,
      url: organizationUrl,
    },
    serviceType: 'Registration of Trademarks',
    areaServed: 'CL',
    url,
  };
};

const ServiceSchemaScriptTag = ({
  context = true,
  name,
  url,
  description,
  organizationName,
  organizationUrl,
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          ServiceSchema({
            context,
            name,
            url,
            description,
            organizationName,
            organizationUrl,
          })
        ),
      }}
    />
  );
};

ServiceSchema.propTypes = {
  context: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationUrl: PropTypes.string.isRequired,
};

ServiceSchemaScriptTag.propTypes = {
  context: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationUrl: PropTypes.string.isRequired,
};

export { ServiceSchema, ServiceSchemaScriptTag };
