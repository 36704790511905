import PropTypes from 'prop-types';

const WebPageSchema = ({
  context = true,
  title,
  description,
  url,
  urlImage,
}) => {
  return {
    ...(context ? { '@context': 'https://schema.org' } : {}),
    '@type': 'WebPage',
    name: title,
    url,
    description,
    inLanguage: 'es',
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: urlImage,
    },
    breadcrumb: {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: url,
        },
      ],
    },
  };
};

const WebPageSchemaScriptTag = ({
  context = true,
  title,
  description,
  url,
  urlImage,
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          WebPageSchema({ context, title, description, url, urlImage })
        ),
      }}
    />
  );
};

WebPageSchema.propTypes = {
  context: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlImage: PropTypes.string.isRequired,
};

WebPageSchemaScriptTag.propTypes = {
  context: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlImage: PropTypes.string.isRequired,
};

export { WebPageSchema, WebPageSchemaScriptTag };
